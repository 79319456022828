@import '../../styles/_breakpoints.scss';

$main-color: #49b4b8;
$main-color--light-theme: #00f7ff;

.leave-status-card {
  width: 90%;
  margin: auto;

  max-width: 1920px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 0.1rem;
  border-radius: 10px;
  margin-top: 2px;

  @include lg {
    max-width: none;
    width: 90%;
  }



  .select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    &>* {
      width: 50%;
    }
  }

  .info-card {
    margin-top: 1rem;

    border-radius: 10px;
  }

  .info-button {
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;

    &:hover {
      filter: contrast(220%);
      transform: scale(1.4);
    }
  }

  .leave-item,
  .leave-item-header {
    margin-bottom: 0.2em;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 2fr 0.5fr;
    grid-template-rows: auto;
    border-radius: 5px;

    position: relative;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

    &__icon {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & * {
      opacity: 0.8;
      font-size: 15px;
      justify-self: flex-start;
      align-self: center;
      padding: 4px;

      @include md {
        font-size: 13px;
      }
    }
  }

  .user-leave-card {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }

  .leave-item-header {
    margin-bottom: 10px;
    border: none;

    font-weight: bold;
    color: $main-color;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;

    &>* {
      padding-left: 1em;
    }
  }
}