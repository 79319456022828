@import 'styles/_breakpoints.scss';
$item_width: 50%;
$item_color: rgb(202, 196, 196);


.time-picker-container {
  width: 100%;
  margin: auto;





  input {
    opacity: 0.7;

  }
}

// TimePicker.scss