@import '../../../styles/_breakpoints.scss';

$header-color: #c0c0c0;

.login-container {
  width: 70%;
  margin: auto;
  min-height: 100vmax;
  padding-top: 10%;
  max-width: 700px;


  @include sm {
    padding-top: 20%;
    max-width: initial;
    width: 100%;
  }

  h1 {
    margin: auto;
    text-align: center;
    font-size: 33px;
  }

  &__label {
    margin: 0.5em;
    text-align: center;
    font-weight: 700;
    opacity: 0.7;

    display: block;
  }

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin: auto;

    input::placeholder {
      font-weight: bold;
    }
  }

  .forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .forgot-password-link {
      margin-top: 1rem;
      font-weight: 700;
      transition: transform 0.1s ease-in;
      opacity: 0.8;

      &:hover {
        filter: brightness(120%);
        transform: scale(1.1);
      }
    }
  }
}