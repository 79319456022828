// Define variables for common colors and styles
$white-color: rgba(245, 245, 245, 0.494);
$background-color: rgba(0, 0, 0, 0.1);
$border-color: rgba(245, 245, 245, 0.494);
$border-radius: 7px;
$button-colors: #49b4b862;

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.add-wroker-form {
    max-height: 700px;
    overflow-y: scroll;





    .icon {
        color: #49b4b8;
    }

    & span {
        font-weight: bold;
        margin: 0 0.3rem;
    }

    &>div {
        display: grid;
        grid-template-columns: 1fr 7fr 1fr;
        grid-template-rows: auto;
        width: 100%;
        margin: 1rem auto;
        text-align: center;
    }
}

@media screen and (max-height: 1080px) {
    .add-wroker-form {
        max-height: 500px;
        overflow-y: scroll;
    }

}