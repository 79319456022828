$color: #c0c0c0c0;

$theme_color: #49b4b8;

.input_container {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.2rem;

  label {
    padding: 1px;
    align-self: flex-start;
    opacity: 0.7;
    font-size: 14px;
    text-decoration: none;

  }

  .input {
    background-color: transparent;
    border: 1px solid $theme_color;
    border-radius: 10px;
    font-size: 14px;
    height: 33px;
    padding: 0.2rem 0.5rem;
    width: 100%;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    &:hover {
      transition: all 0.1s linear;
      filter: brightness(90%);
    }
  }
}