@import '../../../styles/_breakpoints.scss';
$button-colors: #49b4b8;

@mixin icon-animation() {
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.2);
        color: tomato !important;
    }
}

.icon {
    cursor: pointer;
    font-size: 26px;
    @include icon-animation;
}

.place-middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-name-parent {
    width: max-content;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: bold;
    margin-bottom: 1rem;
}

.project-screen {
    margin: auto;
    height: 100vh;
    padding-top: 5%;
    position: relative;
    max-width: 75%;


    @include lg {
        width: 100%;
        max-width: 90%;
    }

    .icon {
        @include icon-animation;
    }

    .content-wrapper {
        border: 2px solid $button-colors;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        padding: 1rem;
        position: relative;

        &>* {
            justify-self: center;
            align-self: stretch;
        }

        @include md {
            display: block;
        }

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .tasks-wrapper {
            margin-top: 1rem;
            width: 100%;
            .tasks-list {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                margin: 0;
                list-style-type: none;
                width: max-content;
                display: block;

                &>* {
                    display: inline;
                }
            }
        }
    }

    &__row1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-radius: 40px;
        margin: auto;
        margin-bottom: 1em;
        padding-bottom: 1rem;
        border-bottom: 2px solid #49b4b8;
        border-radius: 0 !important;

        .buttons-wrapper {
            display: flex;
            justify-content: space-around;
            align-items: center;

            width: 100%;

            .add-buttons-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                text-align: right;

                margin-right: 5%;
                right: 0;
                font-weight: bold;

                flex-direction: column;
                cursor: pointer;

                label {
                    opacity: 0.8;
                    font-size: 12px;
                }

                &__icon {
                    width: 100%;
                    cursor: pointer;
                    margin-right: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    @include icon-animation;
                }
            }
        }

        .project-labels {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            position: relative;
            font-weight: bold;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
            background-color: transparent;
            padding: 0.1em 0.8em;
            border-radius: 30px;
            width: fit-content;



        }
    }

    &__workers-parent {
        padding: 1rem;
        margin: auto;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
            rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        border-radius: 20px;
        margin-top: 1rem;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
        }

        li {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;

            margin: 10px 0;

            min-width: 40%;

            @include md {

                width: 100%;

            }

            .user-card {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                width: 100%;
                border-radius: 10px;
                padding: 1rem;
                border: 1px double rgba(211, 211, 211, 0.125);
                box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
                    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;




                &__name {
                    font-size: 22px;
                    font-weight: 700;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1em;

                    &::before {
                        content: "";
                        position: absolute;

                        right: -1rem;
                        bottom: 50%;
                        height: 2px;

                        transform: translate(10%, 100%);
                        width: 10%;
                        background-color: $button-colors;
                    }
                }

                &__info-parent {
                    width: 100%;

                    position: relative;
                    border-radius: 40px;
                    border-top-left-radius: -40px;
                }
            }
        }

        .worker-icon {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #383838;
            border: 1px solid #49b4b86e;
            padding: 5px;
        }

        &__icon {
            color: $button-colors;
            margin-right: 0.5rem;

            position: relative;
        }
    }

    .add-buttons-container {
        text-align: right;

        position: absolute;
        right: 1%;
        font-weight: bold;
        top: 5%;

        flex-direction: column;
        cursor: pointer;

        label {
            opacity: 0.8;
            font-size: 12px;
        }

        &__icon {
            width: 100%;
            cursor: pointer;
            margin-right: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            @include icon-animation;
        }
    }
}

.modal-users-wrapper {
    width: 600px;

    li {
        display: grid;
        grid-template-columns: 10% 60% 20%;
        grid-template-rows: auto;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        border: 1px solid #282828;
        margin-top: 1em;
        border-radius: 4px;
        padding: 0.3em 1em;
    }

    &__username {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
}

@media (max-height: 700px) {
    .project-screen {
        height: 100vmax;
    }
}