@import "../../../styles/breakpoints";
$header-color: #c0c0c0;

.forget-password-container {
  width: 70%;
  margin: auto;
  height: 100vh;
  max-width: 500px;
  padding-bottom: 10%;

  img {

    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;

    @include md {
      width: 70%;
    }
  }

  h1 {
    margin: auto;
    text-align: center;


  }

  p {
    margin: auto;
    text-align: center;

    opacity: 0.7;
  }

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


  }

  .forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .forgot-password-link,
    .back-to-login-link {
      margin-top: 1rem;
      font-weight: 700;
      transition: transform 0.1s ease-in;
      opacity: 0.8;

      &:hover {
        filter: brightness(120%);
        transform: scale(1.1);
      }
    }
  }
}