@import '../../styles/_breakpoints.scss';
$secoundary-color: #49b4b8;
.add-task-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  @include md {
    width: 100%;
  }
  .add-task-select,
  .add-task-input,
  .add-task-button {
    width: 500px;
    max-width: 700px;
    margin: auto;
    @include md {
      width: 100%;
      max-width: 700px;
    }
  }
}

.time-rapport-wrapper {
  width: 100%;
  margin: auto;
  padding-bottom: 2%;
  padding-top: 5%;
  @include xl {
    width: 90%;
  }

  .total-times-wrapper {
    padding: 1rem;

    font-weight: bold;
    text-align: right;
  }

  .switch-wrapper {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .time-registering-form-parent {
    margin: auto;
    border: 1px solid gray;
    padding: 1rem;
    border-radius: 20px;
    padding-bottom: 3rem;
    position: relative;
    width: 60%;

    @include xl {
      padding: 0;
      padding-bottom: 2rem;
      width: 100%;
    }

    @include md {
      width: 90vw;
      padding-bottom: 1rem;
    }

    h3 {
      color: $secoundary-color;
      padding: 1rem;
      background-color: #383838;
    }

    .time-registering-form {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 10px;

      @include sm {
        * {
          font-size: 12px;
        }
      }

      &__total-times-wrapper {
        padding: 1rem;
        border-bottom: 1px solid lightblue;
        opacity: 0.6;
        text-align: right;

        & > span {
          margin-right: 1rem;
          text-decoration: underline;
        }
      }

      &__label {
        padding: 1rem 0;
        margin-bottom: 1rem;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        background-color: #383838;
        color: white;
  
      }
    }
  }

  .calendars-container {
    width: 70%;
    margin: auto;
    max-width: 1000px;

    @include xxl {
      width: 60%;
    }

    @include xl {
      width: 70%;
    }

    @include md {
      width: 90%;
      padding-top: 0;
    }
    @include sm {
      width: 100%;
      padding-top: 0;
    }
  }

  .dark-icons {
    color: black !important;
  }

  .fileds-wrapper {
    width: 100%;
    max-width: 900px;

    .switch-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .select-wrapper {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

    @include sm{
        flex-direction: column;
      }

      & > * {
        width: 100%;
      }
    }

    .times_parent {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > * {
        width: 100%;
        margin: 10px;
      }

      @include md {
        width: 100%;
        
      }
      @include sm{
        flex-direction: column;
      }
    }

    h1,
    h2 {
      text-align: center;
    }

    label {
      font-weight: 700;
    }

    .button-wr {
      width: 100%;
    }

    .project-hour {
      text-align: center;
      margin-top: 1rem;
    }

    .times-submit-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 768px) {
    .time-rapport-wrapper {
      max-width: 100%;
      height: 100vmax;

      &__times_parent {
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 980px) {
    .time-rapport-wrapper {
      max-width: 99%;
    }
  }
}
