@import 'styles/_breakpoints.scss';
$main-color: #49b4b8;

.minimized-calendar-parent {
  position: absolute;
  bottom: 50% !important;
  left: 0;

  .MuiPickersFadeTransitionGroup-root {
    font-size: 10px;
  }

  .MuiSvgIcon-root {
    color: $main-color !important;
    font-size: 19px;
  }

  @include xl {
    transform: translateY(-200%);
  }

  button {
    @include xxl {
      font-size: 10px !important;
      height: 20px !important;
    }
  }

  .MuiDateCalendar-root {
    @include xxl {
      font-size: 5px !important;
      width: 200px !important;
    }
  }

  .minimized-calendar {
    width: 100%;
    height: 100%;

    @include xl {
      display: none;
    }

    &__icon {
      color: aqua;
      cursor: pointer;
      z-index: 200;
      transform: translateY(-50%);

      &:hover {
        transition: all 0.1s ease-in;
        color: tomato;
      }
    }
  }

  .minimized-calendar-icon {
    @include xl {
      margin-left: 10%;
      padding: 1rem;
    }
  }
}

.minimized-calendar__wrapper {
  max-height: 100px !important;
  width: 100px;
}

.calendar-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.079) 0px 3px 6px;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
  max-height: 600px; // Full height when maximized
  opacity: 1;

  @include sm {
    font-size: 12px;
  }
  .day-cell {
    &:hover {
      transition: 0.2s all ease-in;
      transform: scale(1.1);
      color: tomato !important;
    }
  }
  .guid-component-parent {
    padding: 0;

    @include sm {
      font-size: 8px;
    }

    button {
      @include sm {
        font-size: 7px;
      }
    }
  }
}

.month-header {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    background-color: transparent;
    border: none;
  }
}

.month-details-container--header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.arrows-container {
  padding: 0.7em;
  @include sm {
    padding: 0.1rem;
    font-size: 12px;
  }
}

.arrows-container {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  width: fit-content;
}

.arrows-container button {
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  display: flex;
  margin: auto;
  cursor: pointer;
}

.arrows-container * {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 17px;

  margin: 0 1rem;
  @include xl {
    font-size: 15px;
  }

  @include sm {
    font-size: 12px;
  }
}

.fa-solid:hover {
  transform: scale(1.2);
  transition: transform 4ms ease-in;
}

/* Skeleton Loader CSS */
.skeleton {
  background-color: #565353;
  border-radius: 4px;
  margin: 10px 0;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}
