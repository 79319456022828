@import '../../../styles/_breakpoints.scss';
$button-colors: #49b4b8;

@mixin icon-animation() {
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
    color: tomato !important;
  }
}

.place-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-screen {
  margin: auto;
  height: 100vh;
  padding-top: 5%;

  max-width: 75%;

  @include lg {
    width: 95%;  max-width: none;

  }
&__sub-task-item{
  width: fit-content;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid $button-colors;
}
  .task-header-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 1rem;

    &>* {
      margin: 4px;
      font-weight: bold;
    }
  }

  .content-wrapper {
    border: 1px solid $button-colors;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 1rem;
    position: relative;

    @include md {
      display: block;
    }
  }

  &__row1 {
    @include md {
      width: 100%;
      justify-content: space-around;
    }

    .add-button {
      margin-left: auto;
    }

    &__col1 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      position: relative;
      font-weight: bold;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      background-color: transparent;
      padding: 0.1em 0.8em;
      border-radius: 30px;
      width: max-content;

      &::after {
        width: 100%;
        content: '';
        left: 0;
        bottom: 0;
        height: 2px;
        background-color: #49b4b8;
        position: absolute;
      }
    }
  }

  .add-buttons-container {
    text-align: right;

    position: absolute;
    right: 1%;
    font-weight: bold;
    top: 5%;

    flex-direction: column;
    cursor: pointer;

    label {
      opacity: 0.8;
      font-size: 12px;
    }

    &__icon {
      width: 100%;
      cursor: pointer;
      margin-right: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      @include icon-animation;
    }
  }
}

@media (max-height: 700px) {
  .task-screen {
    height: 100vmax;
  }
}