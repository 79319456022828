@import 'styles/_breakpoints.scss';

.infoContainer {
  display: flex;
  align-items: center;
  padding: 8px 17px;
  border-radius: 4px;
  background-color: #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  width: max-content;
  margin: auto;
  justify-content: center;
  min-width: 200px;
  text-align: center;
  margin: 2% auto;
  font-weight: bold;
  color: #282828cb;

  @include sm {
    padding: 3px 8px;
  }

}

.icon {
  font-size: 0.8em;
  margin-right: 10px;
  @include sm {
      font-size: 12px;
    }
}

.textContent {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;


}

.message {
 font-size: 14px;

  @include sm {
    font-size: 11px;
  }
}

.success {
  border-left: 4px solid green;

}

.error {
  border-left: 4px solid red;

}