@import "styles/breakpoints.scss";

.custom-node {
    background: #485563;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #29323c, #485563);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #29323c, #485563);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border: 0.02px double rgba(255, 255, 255, 0.22);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
}

.flows-container {
    height: 100vh;
    width: 100%;

    @include md {
        overflow: scroll;
        font-size: 8px;
    }

    a {
        display: none !important;
    }
}