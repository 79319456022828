@import 'styles/_breakpoints.scss';
$item_width: 50%;
$item_color: rgb(202, 196, 196);

.date-picker-container {
    padding: 0;

@include sm {
        input {
            padding: 10px 15px !important;
        }
    }
}

@media screen and (max-width: 700px) {}
