 .modal-date-item {
     margin-bottom: 0.5em;
     display: flex;
     justify-content: space-between;
     align-items: center;
     box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
     padding: 0.3em 0;
     border-radius: 5px;
     text-align: left;
     color: whitesmoke;



     &>span {

         text-align: left;
         margin-right: auto;
         opacity: 0.8;
         align-self: flex-start;
         justify-self: flex-start;
         min-width: 200px;

     }
 }