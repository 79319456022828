$items-pading: 5%;
$button-colors: #49b4b8;
@import '../../styles/breakpoints.scss';

@mixin BottomLine {
  position: absolute;
  left: 0;
  bottom: -10%;
  width: 100%;
  height: 1px;
  content: '';
  background-color: rgba(245, 245, 245, 0.329);
  @include sm {
    height: 0;
  }
}

.place-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification {
  height: 100vh;
  &__drawer-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: left;

    border-top: 1px double $button-colors;
    margin-top: 50px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    @include sm {
      align-items: flex-start;
      padding-left: 1rem;
      flex-direction: row;
      margin-top: 0;
    }
    &--light {
      svg {
        color: #867a7a;
      }

      .notification__drawer-container__label {
        color: #282828;
    
      }

      .notification__drawer-container__icon {
        color: black;
      }
    }
  }

  &__drawer-item {
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: relative;
    @include md {
      width: fit-content;
      margin-left: 1rem;
    }
    &::after {
      @include BottomLine;
    }

    &--selected {
      svg {
        color: #49b4b8;
      }

      .notification__drawer-container__label {
        color: $button-colors;
        margin-left: 3px;
        transform: scale(1.1);
      }

      .notification__drawer-container__icon {
        opacity: 1;
        color: $button-colors !important;
      }
    }

    & > * {
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 8fr 1fr;
    grid-template-rows: auto;
    overflow: visible;
    font-weight: 500;

    @include md {
      font-size: 12px;
      display: flex;
      flex-direction: column-reverse;
    }

    &__main {
      padding: $items-pading;
      border-top: 1px solid $button-colors;
      border-right: 1px solid $button-colors;
      border-top-right-radius: 30px;

      height: 100%;
      max-height: 1080px;
      overflow-y: scroll;
      .notification-form {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        max-width: 700px;

        margin: auto;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
          rgba(0, 0, 0, 0.23) 0px 3px 6px;
        padding: 3%;
        border-radius: 20px;

        &--dark {
          .notification-form__field {
            &::placeholder {
              color: rgb(228, 225, 225) !important;
              font-weight: bold;
              opacity: 0.8;
            }
          }
        }

        &--light {
          .notification-form__field {
            &::placeholder {
              color: rgb(22, 22, 22) !important;
              font-weight: 500;
              opacity: 0.9;
            }
          }
        }

        &__label {
          display: inline-block;
          width: 100%;
          padding-left: 3%;
          padding-bottom: 3%;
        }

        &__field {
          border-radius: 7px !important;
          margin-top: 7px;
        }

        &__button-container {
          text-align: left;
          margin-right: auto;
        }

        fieldset,
        textarea {
          border: none;
          border-bottom: 1px solid $button-colors;
          border-radius: 10px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding: 1rem;
          width: 95% !important;
          font-size: 16px;
          margin: auto;
        }

        svg {
          color: white;
        }

        & > * {
          margin: 0.3rem;
        }

        textarea {
          background-color: transparent;

          &::placeholder {
            font-size: 14px;
            color: rgb(233, 231, 231);
          }
        }
      }

      .navigate-button {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.5);
          filter: brightness(150%);
        }
      }
    }
  }
}

.expanded-notifications {
  padding-top: 5%;

  &__message {
    border-radius: 5px;
    padding: 0.5rem;
    padding-right: 0;
  }

  &__reply--fields {
    border: 1px solid #49b4b8;
    margin-top: 1rem;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  &__new-message {
    text-align: right;
    font-size: 33px;
  }

  .expanded-notifications__items {
    margin: auto;
    width: 60%;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    @include xl {
      padding: 0;
      width: 90%;
    }

    &__main {
      max-width: 700px;
      margin: 10px auto;

      padding: 1rem;

      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.005) 0px 1px 3px,
        rgba(0, 0, 0, 0.066) 0px 1px 2px;
    }

    &__reply {
      margin-right: 2rem;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;

      &:hover {
        background-color: #49b4b8;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
          rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      }
    }

    &__row1 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__row1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        margin-top: 1rem;
      }
    }

    &__row2 {
      margin: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      &__title {
        display: flex;
        justify-content: centfler;
        align-items: flex-end;
        display: inline-block;
        margin-bottom: 1rem;
      }
    }

    &__user-icon {
      background-color: $button-colors;
      padding: 0.3rem;
      border-radius: 50%;
      font-size: 33px;
    }
  }

  &__reply {
    text-align: right;
    font-size: 33px;
  }
}

@media only screen and (max-height: 600px) {
  .expanded-notifications {
    height: 100vmax;
  }
}
