 .back-button {
     margin-left: 1rem;
     display: inline;
     cursor: pointer;
     min-width: 100px;
     padding: 10px;
     transition: color 0.1s ease-in-out;
     color: #898686;
     font-weight: bold;

     &:hover {
         color: #ffff;
         text-shadow: 2px 2px 2px black;

     }
 }