@import 'styles/_breakpoints.scss';
.leaves-review-container {
  padding: 1rem;
  height: 100vh;
  margin: auto;
  @include sm {
    width: 100vw;
    padding: 0;
  }

  @include sm {
    * {
      font-size: 10px;
    }
  }

  .select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: fit-content;
    width: 90% !important;
    & > * {
      width: 50%;
    }
  }
}
