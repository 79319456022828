    .add-button {

        transition: all 0.1s ease-in-out;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 50%;

        color: #49b4b8;

        &:hover {
            transform: scale(1.2);
            color: #49b4b8;
        }
    }