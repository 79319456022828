@import 'styles/_breakpoints.scss';
$button-colors: #49b4b8;

.user-dashboard {
  position: relative;
  &__calendar {
    position: absolute;
    right: 5%;
    font-size: 33px !important;
    cursor: pointer;
  }
}
