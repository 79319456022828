// Define variables for common colors and styles
$white-color: rgba(245, 245, 245, 0.494);
$background-color: rgba(0, 0, 0, 0.1);
$border-color: #49b4b8;
$border-radius: 7px;
$button-colors: #49b4b862;
.create-user-container {
width: 100vw;
max-width: 900px;
margin: auto;
    .user-roles-parent {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-self: flex-start;
    justify-self: flex-start;
    width: 100%;
    margin-top: 1rem;
    }
}